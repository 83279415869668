body {
  background-color: #f5f5f5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  font-family: JetBrains Sans, sans-serif;
  display: flex;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.controls {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
}

button {
  cursor: pointer;
  color: #fff;
  background-color: #4080ff;
  border: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-family: JetBrains Sans, sans-serif;
  font-weight: bold;
  transition: background-color .3s;
  box-shadow: 1px 1px 6px #0000001a;
}

button:hover {
  background-color: #386ad4;
}

button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

select {
  background-color: #fff;
  border: none;
  border-radius: 6px;
  padding: 8px 10px;
  font-family: JetBrains Sans, sans-serif;
  font-weight: bold;
  box-shadow: 1px 1px 6px #0000001a;
}

.spinner {
  border: 5px solid #f5f5f5;
  border-top-color: #4080ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: .8s linear infinite spin;
  display: none;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #fff;
  text-align: center;
  background-color: #f55;
  border-radius: 6px;
  max-width: 100%;
  height: auto;
  padding: 1em;
  font-family: JetBrains Mono, monospace;
  font-weight: bold;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px #00000026;
}

.image-container {
  aspect-ratio: 16 / 9;
  background-color: #ddd;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
  width: 100%;
  max-width: calc(100vh - 10rem);
  margin-bottom: 1em;
  position: relative;
  box-shadow: 2px 2px 10px #00000026;
}

img {
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: calc(100vh - 10rem);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 10px #00000026;
}

.outer-container {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 1rem;
  display: flex;
}

@font-face {
  font-family: JetBrains Sans;
  src: url("JetBrainsSans-Regular.c4fbe15b.woff2") format("woff2");
  font-weight: normal;
}

@font-face {
  font-family: JetBrains Sans;
  src: url("JetBrainsSans-SemiBold.fb9cb604.woff2") format("woff2");
  font-weight: bold;
}

.main-title {
  font-size: 24px;
}

.control-button, .style-select {
  font-size: 16px;
}

@media (width >= 992px) {
  .main-title {
    font-size: 36px;
  }

  .control-button, .style-select {
    font-size: 24px;
  }
}

@media (width >= 1440px) {
  .main-title {
    font-size: 48px;
  }

  .control-button, .style-select {
    font-size: 32px;
  }
}

.control-label {
  font-size: 16px;
  font-weight: bold;
}

@media (width >= 992px) {
  .control-label {
    font-size: 24px;
  }
}

@media (width >= 1440px) {
  .control-label {
    font-size: 32px;
  }
}

.error-message {
  font-size: 16px;
}

@media (width >= 992px) {
  .error-message {
    font-size: 24px;
  }
}

@media (width >= 1440px) {
  .error-message {
    font-size: 32px;
  }
}

.footer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  margin-top: 1rem;
  display: flex;
}

.footer-link {
  color: #333;
  font-weight: bold;
  text-decoration: none;
  transition: color .3s;
}

.footer-link:hover {
  color: #4080ff;
}

.footer-link:not(:last-child) {
  margin-right: 1rem;
}

select:hover, select:focus {
  background-color: #e5e5e5;
  outline: none;
  box-shadow: 1px 1px 6px #00000026, 0 0 0 2px #0000ff1a;
}

.blurred {
  filter: blur(14px);
}
/*# sourceMappingURL=app.ead978fc.css.map */
